import React, { useRef } from "react";
import useUpdateChart from "./useUpdateChart";
import useInitializeChart from "./useInitializeChart";
import { useTheme } from "@material-ui/core/styles";

function RaidChart({ selected, data, dataOld, loading, setSelectedStr, theme }) {
    const canvasRef = useRef(null);
    const selectedRef = useRef(selected);
    const chartInstance = useInitializeChart(canvasRef, data, selected, loading, setSelectedStr, theme, selectedRef);
    useUpdateChart(selected, data, dataOld, chartInstance, setSelectedStr, theme, selectedRef);

    return (
        <canvas ref={canvasRef}></canvas>
    );
}

export default RaidChart;