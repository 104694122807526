import React, { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import ThemeContext from "./ThemeContext";
import BrowserWindowContainer from "./BrowserWindowContainer";

const getColor = (mode, light, dark) => {
    return mode === "light" ? light : dark;
};

const ThemeProviderComponent = ({ children }) => {
    const modePref = useMediaQuery("(prefers-color-scheme: dark)")
        ? "dark"
        : "light";
    const [mode, setMode] = useState(modePref);

    useEffect(() => {
        document.documentElement.style.setProperty("color-scheme", mode);
    }, [mode]);

    const handleModeChange = () => {
        const newMode = mode === "light" ? "dark" : "light";
        setMode(newMode);
        document.documentElement.style.setProperty("color-scheme", newMode);
    };

    const getDesignTokens = (mode) => {
        return {
            palette: {
                mode: mode,
                type: mode,
                primary: {
                    light: "#c54949",
                    main: "#b71c1c",
                    dark: "#801313",
                    // contrastText: getColor(mode, "#2d2f31", "#f3f6fc"),
                    contrastText: "#f3f6fc",
                },
                secondary: {
                    light: "#93a6af",
                    main: "#78909c",
                    dark: "#54646d",
                    // contrastText: getColor(mode, "#2d2f31", "#f3f6fc"),
                    contrastText: "#f3f6fc",
                },
                divider:
                    mode === "light"
                        ? "rgba(0, 0, 0, 0.12)"
                        : "rgba(255, 255, 255, 0.12)",
                background: {
                    default: mode === "light" ? "#fff" : "#121212",
                    paper: mode === "light" ? "#fff" : "#121212",
                },
                text: {
                    primary: mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff",
                    secondary:
                        mode === "light"
                            ? "rgba(0, 0, 0, 0.6)"
                            : "rgba(255, 255, 255, 0.7)",
                    disabled:
                        mode === "light"
                            ? "rgba(0, 0, 0, 0.38)"
                            : "rgba(255, 255, 255, 0.5)",
                },
            },
        };
    };

    document.documentElement.classList.remove("light", "dark");
    document.documentElement.classList.add(mode);

    const theme = createTheme(getDesignTokens(mode));

    console.log("Rendering ThemeProviderComponent");

    return (
        <ThemeContext.Provider value={{ mode, toggleMode: handleModeChange }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserWindowContainer>
                    {" "}
                    {children}{" "}
                </BrowserWindowContainer>{" "}
            </ThemeProvider>{" "}
        </ThemeContext.Provider>
    );
};

export default ThemeProviderComponent;
