import React from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

const PolyAnalHome = () => {
    return (
        <Grid
            container
            spacing={2}
            display="flex"
            sx={{ justifyContent: "center", alignItems: "center" }}
        >
            <Grid
                item
                xs={12}
                sx={{ justifyContent: "center", alignItems: "center" }}
            >
                <Paper elevation={4} sx={{ padding: '8px'}}>
                    <Grid
                        container
                        spacing={1}
                        display="flex"
                        sx={{ justifyContent: "center", alignItems: "center" }}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <List
                                sx={{
                                    width: "100%",
                                    padding: "8px",
                                    maxHeight: "160px",
                                    overflowY: "auto",
                                }}
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                    (value) => (
                                        <ListItem
                                            key={value}
                                            disableGutters
                                            secondaryAction={
                                                <>
                                                    <IconButton aria-label="settings">
                                                        <SettingsIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="delete">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            }
                                        >
                                            <ListItemText
                                                primary={`Account ${value}`}
                                            />
                                        </ListItem>
                                    )
                                )}
                            </List>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Add Account
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default PolyAnalHome;
