const createChartData = (data, selected) => {
    // console.log(selected, data, data[selected]);
    const selectedDataObj = data[selected];
    const selectedData = {
        labels: selectedDataObj.labels,
        data: selectedDataObj.data,
    };

    const backgroundColors = selectedData.labels.map((key) => {
        if (selectedData.labels.length === 3) {
            if (key === "hundos") return "rgba(255, 0, 0, 0.5)";
            if (key === "shinys") return "rgba(255, 215, 0, 0.5)";
            if (key === "shundos") return "rgba(128, 0, 128, 0.5)";
        } else {
            if (key === "hundos") return "rgba(255, 0, 0, 0.5)";
            if (key === "shinys") return "rgba(255, 215, 0, 0.5)";
            if (key === "shundos") return "rgba(128, 0, 128, 0.5)";
            return "rgba(0, 128, 0, 0.5)";
        }
    });

    return {
        labels: selectedData.labels,
        datasets: [
            {
                data: selectedData.data,
                backgroundColor: backgroundColors,
                borderColor: backgroundColors.map((color) =>
                    color.replace("0.5", "1")
                ),
                borderWidth: 1,
            },
        ],
    };
};

export default createChartData;
