import React from "react";
import {
    DiscordMessage,
    DiscordOptionsContext,
    DiscordEmbed,
    DiscordEmbedFields,
    DiscordEmbedField,
} from "@danktuary/react-discord-message";
import discordOptions from "./Components/discordOptions";
import getEmbedProps from "./Components/EmbedProps";
import {
    generateStatsField,
    generateMovesField,
    generateDisplayField,
    generateLocationField,
    generateLocationInfoField,
    generateMapsField,
    generatePvPField,
    generateTimeField,
} from "./Components/FieldGenerators";

const CooldownPreview = ({ settings }) => {
    const { hide = [], image = "small" } = settings;
    const imageUrl =
        "https://img.pokemondb.net/sprites/go/shiny/charmander.png";
    const embedProps = {};

    if (!hide.includes("color")) {
        embedProps.color = "#000000";
    }

    if (!hide.includes("author")) {
        embedProps.authorName = !hide.includes("censor") ? "Us...00" : "Username00";
    }

    if (!hide.includes("title")) {
        embedProps.title = "Cooldown";
    }

    let fields = [];

    if (!hide.includes("time")) {
        generateTimeField(fields, hide);
    }

    if (!hide.includes("location")) {
        generateLocationField(fields, hide);
    }

    if (!hide.includes("location_info")) {
        generateLocationInfoField(fields, hide);
    }

    if (!hide.includes("maps")) {
        generateMapsField(fields, hide);
    }

    return (
        <>
            {(image === "large" || image === "both") && (
                <DiscordEmbed image={imageUrl} color={embedProps.color} />
            )}
            <DiscordEmbed {...embedProps}>
                <DiscordEmbedFields slot="fields">
                    {fields.map((field, index) => (
                        <DiscordEmbedField
                            key={index}
                            fieldTitle={field.name}
                            inline={field.inline}
                        >
                            {field.value}
                        </DiscordEmbedField>
                    ))}
                </DiscordEmbedFields>
            </DiscordEmbed>
        </>
    );
};

export default CooldownPreview;
