import React from "react";
import {
    DiscordMessage,
    DiscordOptionsContext,
    DiscordEmbed,
    DiscordEmbedFields,
    DiscordEmbedField,
} from "@danktuary/react-discord-message";
import discordOptions from "./Components/discordOptions";
import getEmbedProps from "./Components/EmbedProps";
import {
    generateStatsField,
    generateMovesField,
    generateDisplayField,
    generateLocationField,
    generateLocationInfoField,
    generateMapsField,
    generatePvPField,
    generateTimeField,
} from "./Components/FieldGenerators";
import Markdown from "marked-react";

const CampPreview = ({ settings }) => {
    const { hide = [], image = "small" } = settings;
    const imageUrl =
        "https://img.pokemondb.net/sprites/go/shiny/charmander.png";
    const embedProps = getEmbedProps(
        hide,
        image,
        imageUrl,
        "#65F258",
        "Camp Wild"
    );
    let fields = [];

    if (!hide.includes("stats")) {
        generateStatsField(fields, hide);
    }

    if (!hide.includes("moves")) {
        generateMovesField(fields, hide);
    }

    if (!hide.includes("display")) {
        generateDisplayField(fields, hide);
    }

    if (!hide.includes("camp")) {
        const campStr = "🕰️ `08:53:26`\n⏲️ `in 5 Minutes`";
        const camplines = campStr.split("\n");

        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Estimated Catch Time**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {camplines.map((line, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{line}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }

    if (!hide.includes("pvp")) {
        generatePvPField(fields, hide);
    }

    if (!hide.includes("time")) {
        generateTimeField(fields, hide);
    }

    if (!hide.includes("location")) {
        generateLocationField(fields, hide);
    }

    if (!hide.includes("location_info")) {
        generateLocationInfoField(fields, hide);
    }

    if (!hide.includes("maps")) {
        generateMapsField(fields, hide);
    }

    return (
        <>
            {(image === "large" || image === "both") && (
                <DiscordEmbed image={imageUrl} color={embedProps.color} />
            )}
            <DiscordEmbed {...embedProps}>
                <DiscordEmbedFields slot="fields">
                    {fields.map((field, index) => (
                        <DiscordEmbedField
                            key={index}
                            fieldTitle={field.name}
                            inline={field.inline}
                        >
                            {field.value}
                        </DiscordEmbedField>
                    ))}
                </DiscordEmbedFields>
            </DiscordEmbed>
        </>
    );
};

export default CampPreview;
