import CampPreview from "./campPreview";
import CatchPreview from "./catchPreview";
import CooldownPreview from "./cooldownPreview";
import EncounterPreview from "./encounterPreview";
import FleePreview from "./fleePreview";
import HatchPreview from "./hatchPreview";
import RaidPreview from "./raidPreview";
import TradePreview from "./tradePreview";

const WebhookPreview = ({ settings }) => {
    switch (settings.endpoint) {
        case "encounter":
            return <EncounterPreview settings={settings} />;
        case "catch":
            return <CatchPreview settings={settings} />;
        case "flee":
            return <FleePreview settings={settings} />;
        case "cooldown":
            return <CooldownPreview settings={settings} />;
        case "camp":
            return <CampPreview settings={settings} />;
        case "hatch":
            return <HatchPreview settings={settings} />;
        case "raid":
            return <RaidPreview settings={settings} />;
        case "trade":
            return <TradePreview settings={settings} />;
        default:
            return <div />;
    }
};

export default WebhookPreview;
