import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SettingsIcon from "@mui/icons-material/Settings";
import Backdrop from "@mui/material/Backdrop";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import FilterGuideBackdrop from "./FilterGuideBackdrop";

function TextFieldWithIcon({ onChange, endpoint }) {
    const [open, setOpen] = useState(false);

    const handleSave = (newFilter) => {
        onChange({ target: { value: newFilter } }); // Update the main TextField value
        setOpen(false);
    };

    return (
        <div>
            <TextField
                value={endpoint.filter}
                onChange={onChange}
                label="Filter Key"
                variant="outlined"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SettingsIcon
                                onClick={() => setOpen(true)}
                                style={{ cursor: "pointer" }}
                            />
                        </InputAdornment>
                    ),
                }}
            />

            <FilterGuideBackdrop
                open={open}
                currentFilter={endpoint.filter} // Pass the current filter value
                handleClose={() => setOpen(false)}
                handleSave={handleSave} // Pass the save handler
            />
        </div>
    );
}

export default TextFieldWithIcon;
