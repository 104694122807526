import { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import createChartConfig from "./ChartConfig";

function useUpdateChart(
    selected,
    data,
    dataOld,
    chartInstance,
    setSelectedStr,
    theme,
    selectedRef
) {
    const [chartData, setChartData] = useState({});
    var selectLast;

    useEffect(() => {
        // Update the ref value whenever the `selected` variable changes
        selectedRef.current = selected;
    }, [selected]);

    useEffect(() => {
        if (!chartInstance?.data.datasets[0]) return;

        // console.log(`Chart Instance:`);
        // console.dir(chartInstance);
        // console.log(`dataOld:`);
        // console.dir(dataOld);
        var data_old = chartInstance.data.datasets[0].data;
        var data_new = data[selected].data;
        var data_old_json = JSON.stringify(data_old);
        var data_new_json = JSON.stringify(data_new);
        if (
            (selectLast !== selected && data && dataOld) ||
            (data_old && data_new && data_old_json !== data_new_json)
        ) {
            if (data_old_json !== data_new_json) {
                const config = createChartConfig(data, selectedRef, theme);
                chartInstance.data = config.data;
                chartInstance.update();
            }

            if (data_old_json !== data_new_json) {
                // Implement a Animation from old data to new data, if the its the same
                // chartInstance.options.animation = {
                //     duration: 1000,
                //     onProgress: function (animation) {
                //         const progress =
                //             animation.currentStep / animation.numSteps;
                //         const data = chartInstance.data.datasets[0]?.data;
                //         // Interpolate the values from data_old to data_new
                //         for (let i = 0; i < data.length; i++) {
                //             data[i] =
                //                 data_old[i] +
                //                 (data_new[i] - data_old[i]) * progress;
                //         }
                //         chartInstance.data = data;
                //         chartInstance.update();
                //     },
                // };
            } else {
                selectLast = selected;
            }

            setSelectedStr(data[selected].menuItem);
        }
    }, [selected, data, chartInstance]);

    return { chartData, setChartData };
}


export default useUpdateChart;
