import { DiscordDefaultOptions } from "@danktuary/react-discord-message";

const discordOptions = {
    ...DiscordDefaultOptions,
    profiles: {
        bot: {
            author: "Webhook Message",
            avatar: "https://cdn.discordapp.com/icons/1100461343460561036/8ca0c1c34d74f4d34c75870e127b78dc.webp",
        },
    },
};

export default discordOptions;
