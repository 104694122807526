import React, { useState, useEffect, useRef, useContext } from "react";
import ChannelConfiguration from "./ChannelConfiguration";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ModeToggle from "../Theme/ModeToggle";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { AppStateContext } from "../AppStateContext";
import { ThemeContext } from "../Theme/ThemeContext";
import { useTheme } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert"; // For the alert functionality within the Snackbar
import {
    encounterEmbed,
    catchEmbed,
    fleeEmbed,
    hatchEmbed,
    campEmbed,
    cooldownEmbed,
    tradeEmbed,
    raidEmbed,
} from "./endpointMessages";
import {
    AppBar,
    Toolbar,
    Typography,
    useScrollTrigger,
    Box,
    InputAdornment,
    IconButton,
    CssBaseline,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";

const hoverStyles = {
    color: "text.primary",
    borderColor: "secondary.dark",
    ".material-symbols-outlined": {
        fontVariationSettings: `'FILL' 1, 'wght' 400, 'GRAD' 200, 'opsz' 24`,
    },
};

function WebhookManager(props) {
    const theme = useTheme();
    const { mode, toggleMode } = useContext(ThemeContext);
    const {
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        routeType,
        setRouteType,
    } = useContext(AppStateContext);

    useEffect(() => {
        setRouteType("public");
    }, [setRouteType]);

    const [fileName, setFileName] = useState("discord_webhook");

    const [buttonWidth, setButtonWidth] = useState(null);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    // useEffect(() => {
    //     // if (anchorRef.current) {
    //     //     setButtonWidth(anchorRef.current.offsetWidth);
    //     // }

    //     // Optional: Handle window resize
    //     const handleResize = () => {
    //         if (anchorRef.current) {
    //             setButtonWidth(anchorRef.current.offsetWidth);
    //         }
    //     };
    //     window.addEventListener("resize", handleResize);
    //     return () => {
    //         window.removeEventListener("resize", handleResize);
    //     };
    // }, []);

    const [channels, setChannels] = useState([]);

    useEffect(() => {
        setIsLoading(false);
        setIsAuthenticated(true);
    }, [setIsLoading, setIsAuthenticated]);

    async function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const fileContent = e.target.result;
                try {
                    const parsedContent = JSON.parse(fileContent);

                    if (parsedContent.channels) {
                        // file-label
                        let fileLabel = document.getElementById("file-label");
                        fileLabel.value = file.name.replace(".json", "");

                        const importedChannels = parseWebhooksToChannels(
                            parsedContent.channels
                        );
                        setChannels(importedChannels);
                    }
                } catch (err) {
                    console.error("Error parsing uploaded JSON file:", err);
                }
            };

            reader.readAsText(file);
        }
    }

    function generateWebhooks(channels, mainUrl) {
        return channels.map((channel) => {
            // Initialize the structure for the new format
            const newChannel = {
                name: channel.name,
                url: channel.url,
                webhooks: {},
            };

            // Process each endpoint in the channel
            channel.endpoints.forEach((endpoint) => {
                // Check if this endpoint type already exists, if not, initialize it
                if (!newChannel.webhooks[endpoint.endpoint]) {
                    newChannel.webhooks[endpoint.endpoint] = {};
                }

                // Map the old format to the new structure
                const webhookSettings = newChannel.webhooks[endpoint.endpoint];

                // Add filter if it's enabled and exists
                if (endpoint.filter) {
                    webhookSettings.filter = endpoint.filter;
                }

                // Add hide settings if they exist
                if (endpoint.hide && endpoint.hide.length > 0) {
                    webhookSettings.hide = endpoint.hide;
                }

                // Add image settings if it's different from 'small'
                if (endpoint.image && endpoint.image !== "small") {
                    webhookSettings.image = endpoint.image;
                }

                // Add minimum cooldown for 'camp' endpoint
                if (endpoint.endpoint === "camp" && endpoint.minCooldown > 0) {
                    webhookSettings.minCooldown = endpoint.minCooldown;
                }
            });

            return newChannel;
        });
    }

    function parseWebhooksToChannels(channels) {
        return channels.map((channel) => {
            // Initialize the structure for each channel
            const newChannel = {
                id: uuidv4(),
                name: channel.name,
                url: channel.url,
                endpoints: [],
            };

            // Process each webhook (endpoint) in the channel
            Object.entries(channel.webhooks).forEach(
                ([endpointKey, endpointSettings]) => {
                    console.log(endpointKey);
                    console.log(endpointSettings);
                    newChannel.endpoints.push({
                        id: uuidv4(),
                        endpoint: endpointKey,
                        filter: endpointSettings.filter || "",
                        image: endpointSettings.image || "small",
                        minCooldown: endpointSettings.minCooldown || 20,
                        hide: endpointSettings.hide || [],
                    });
                    console.log(newChannel);
                }
            );

            return newChannel;
        });
    }

    function handleDownload() {
        // Check for duplicate channel names
        const channelNames = channels.map((channel) => channel.name);
        const hasDuplicates =
            new Set(channelNames).size !== channelNames.length;

        if (hasDuplicates) {
            // Display a toast notification
            console.log("Error");
            setSnackbarMessage(
                "Duplicate channel names found. Please ensure all channel names are unique."
            );
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            return;
        }

        // Proceed with file download if no duplicates
        const webhooks = generateWebhooks(channels);
        const dataToDownload = { channels: webhooks };

        const blob = new Blob([JSON.stringify(dataToDownload, null, 2)], {
            type: "application/json",
        });
        const url = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `${fileName}.json`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    const handleButtonClick = () => {
        document.getElementById("upload-button-file").click();
    };

    return (
        // <div
        //     style={{
        //         display: "flex",
        //         flexDirection: "column",
        //         height: "100vh",
        //     }}
        // >
        <React.Fragment>
            <AppBar
                position="sticky"
                sx={{
                    marginBottom: "8px",
                    backgroundColor: "background.paper",
                }}
                elevation={5}
            >
                <Toolbar>
                    <Input
                        accept=".json"
                        style={{ display: "none" }}
                        id="upload-button-file"
                        type="file"
                        onChange={handleFileChange}
                        size="small"
                        hidden={true}
                    />
                    <TextField
                        id="file-label"
                        label="File Name"
                        defaultValue="webhook_settings"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    .json
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        sx={{
                            margin: "16px 24px 8px 0px",
                        }}
                        onChange={(e) => setFileName(e.target.value)}
                        size="small"
                    />
                    <ButtonGroup
                        variant="outlined"
                        color="primary"
                        size="small"
                    >
                        <Tooltip title="Upload">
                            <Button
                                onClick={handleButtonClick}
                                sx={{
                                    borderRadius: "9999px",
                                    border: `1px solid`,
                                    color: "secondary.main",
                                    borderColor: "secondary.dark",
                                    ":hover": hoverStyles,
                                }}
                            >
                                <span class="material-symbols-outlined">
                                    upload_file
                                </span>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Download">
                            <Button
                                onClick={handleDownload}
                                sx={{
                                    borderRadius: "9999px",
                                    border: `1px solid`,
                                    color: "secondary.main",
                                    borderColor: "secondary.dark",
                                    ":hover": hoverStyles,
                                }}
                            >
                                <span class="material-symbols-outlined">
                                    file_save
                                </span>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Mode">
                            <Button
                                onClick={toggleMode}
                                sx={{
                                    borderRadius: "9999px",
                                    border: `1px solid`,
                                    color: "secondary.main",
                                    borderColor: "secondary.dark",
                                    ":hover": hoverStyles,
                                }}
                            >
                                <span className={`material-symbols-outlined`}>
                                    {mode === "dark" ? (
                                        <span
                                            className={`material-symbols-outlined`}
                                        >
                                            {" "}
                                            dark_mode{" "}
                                        </span>
                                    ) : (
                                        <span
                                            className={`material-symbols-outlined`}
                                        >
                                            {" "}
                                            light_mode{" "}
                                        </span>
                                    )}
                                </span>
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Toolbar>
            </AppBar>
            {/* <Box sx={{ my: 2 }}> */}
            {/* <div style={{ flex: 1, overflowY: "auto" }}> */}
            <ChannelConfiguration
                id="channels"
                channels={channels}
                setChannels={setChannels}
                openSnackbar={openSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                snackbarSeverity={snackbarSeverity}
                snackbarMessage={snackbarMessage}
            />
            {/* </div> */}
            {/* </Box> */}

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
        // </div>
    );
}

export default WebhookManager;
