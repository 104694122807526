import React, { useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";

function RaidSelect({ data, selectedStr, onChange }) {
    const theme = useTheme();

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="select-raid" color="primary">
                Select a Raid
            </InputLabel>
            <Select
                label="Select a Raid"
                value={selectedStr}
                onChange={onChange}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                    sx: {
                        "& .MuiMenu-paper": {
                            backgroundColor: "dark.primary",
                            color: "text.light",
                        },
                        "& .MuiMenuItem-root:hover": {
                            backgroundColor: "dark.secondary",
                            color: "text.white",
                        },
                        "& .Mui-selected": {
                            backgroundColor: "primary.main",
                            color: "text.white",
                        },
                    },
                }}
                sx={{
                    color: "text.primary",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "red",
                    },
                    ".MuiSvgIcon-root": {
                        color: "text.primary",
                    },
                    "&:before": {
                        borderBottom: `1px solid ${theme.palette.primary.light}`,
                    },
                    "&:hover": {
                        ":before": {
                            borderBottom: `1px solid ${theme.palette.primary.dark}`,
                        },
                    },
                    "& .MuiMenuItem-root": {
                        backgroundColor: "dark.primary",
                    },
                    "& .MuiMenu-paper": {
                        backgroundColor: "dark.primary",
                    },
                }}
            >
                {data.map((item, index) => (
                    <MenuItem
                        key={item.menuItem}
                        value={item.menuItem}
                        data-index={index}
                    >
                        {item.menuItem}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default RaidSelect;
