import createChartData from "./ChartData";

const readableInt = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 0,
    useGrouping: true,
});

const createChartConfig = (data, selectedRef, theme) => {
    // console.log(selected, data, data[selected]);
    return {
        type: "bar",
        data: createChartData(data, selectedRef?.current),
        options: {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: "y",
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: theme.palette.text.primary,
                    },
                },
                tooltip: {
                    family: "Roboto",
                    fontWeight: "bold",
                    bodyFont: {
                        size: 14,
                    },
                    enabled: true,
                    intersect: false,
                    mode: "index",
                    axis: "y",
                    callbacks: {
                        label: function (context) {
                            const selected = selectedRef?.current;
                            const label = context.label || "";
                            const value = context.parsed.x;
                            const total = data[selected]?.count || 0;
                            const percentage = total
                                ? (value / total) * 100
                                : 0;
                            const percentageStr =
                                percentage === 0
                                    ? "0"
                                    : percentage.toFixed(
                                          percentage < 1 ? 3 : 2
                                      );
                            const oneToValue =
                                percentage === 0
                                    ? "0 : 0"
                                    : `1 : ${readableInt.format(
                                          Math.ceil(100 / percentage)
                                      )}`;

                            return `${label}: ${value} (${percentageStr} % | ${oneToValue})`;
                        },
                    },
                },
            },
            scales: {
                y: {
                    ticks: {
                        // color: theme.palette.text.primary,
                    },
                    grid: {
                        color: "rgba(127, 127, 127, .33)",
                    },
                },
                x: {
                    ticks: {
                        // color: theme.palette.text.primary,
                    },
                    grid: {
                        color: "rgba(127, 127, 127, .33)",
                    },
                },
            },
        },
    };
};

export default createChartConfig;
