import Markdown from "marked-react";

const getEmbedProps = (hide, image, imageUrl, color, source) => {
    let embedProps = {};

    if (!hide.includes("color")) {
        embedProps.color = color;
    }

    if (!hide.includes("image") && (image === "small" || image === "both")) {
        embedProps.thumbnail = imageUrl;
    }

    if (!hide.includes("author")) {
        let author = [];
        if (!hide.includes("user")) {
            author.push(!hide.includes("censor") ? "Us...00" : "Username00");
        }
        if (!hide.includes("source")) {
            author.push(source);
        }
        embedProps.authorName = author.join(" | ");
    }

    if (!hide.includes("title")) {
        embedProps.title = (
            <div className="markdown-title">
                <Markdown>
                    {"✨💯 **Charmander** `dex:form:costume:gender`"}
                </Markdown>
            </div>
        );
    }

    return embedProps;
};

export default getEmbedProps;
