import React, { useContext } from "react";
import { IconButton, useTheme } from "@mui/material/";
import ThemeContext from "./ThemeContext";

const hoverStyles = {
    color: "primary.main",
    ".material-symbols-outlined": {
        fontVariationSettings: `'FILL' 1, 'wght' 400, 'GRAD' 200, 'opsz' 24`,
    },
};

const ModeToggle = ({ hovered }) => {
    const theme = useTheme();
    const { mode, toggleMode } = useContext(ThemeContext);

    return (
        <IconButton
            onClick={toggleMode}
            size="large"
            sx={{
                borderRadius: "9999px",
                border: `1px solid`,
                color: theme.palette.text.secondary,
                ...(hovered ? hoverStyles : {}),
                ":hover": hoverStyles,
            }}
        >
            {mode === "dark" ? (
                <span className={`material-symbols-outlined`}> dark_mode </span>
            ) : (
                <span className={`material-symbols-outlined`}> light_mode </span>
            )}
        </IconButton>
    );
};

export default ModeToggle;
