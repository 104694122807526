import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, Typography, TextField } from "@mui/material";
import { AppStateContext } from "../AppStateContext";
import { useAuthRequest } from "../DiscordLogin/AuthRequestContext";

function KeyGen() {
    const { isLoading, isAuthenticated, setIsLoading, setIsAuthenticated } =
        useContext(AppStateContext);
    const authRequest = useAuthRequest(); // Use the authRequest function from context

    useEffect(() => {
        if (isLoading) {
            console.log("KeyGen setIsLoading(false)");
            setIsLoading(false);
        }
    }, [isLoading, setIsLoading]);

    const [newKeyResponse, setNewKeyResponse] = useState("");
    // const [useKeyResponse, setUseKeyResponse] = useState("");
    // const [verifyKeyResponse, setVerifyKeyResponse] = useState("");

    // // State for input values
    // const [key, setKey] = useState("");
    // const [discordUsernameHash, setDiscordUsernameHash] = useState("");

    const handleNewKeyClick = async () => {
        try {
            // Use authRequest function to make authenticated requests
            const response = await authRequest("GET", "/api/new-key");
            console.debug(response);
            setNewKeyResponse(response.key);
        } catch (error) {
            console.error("Error fetching new key:", error);
            setNewKeyResponse("Failed to fetch new key");
        }
    };

    const apiKey = "db2664a7-d642-49f2-a587-829a9eb19dfe"

    // const handleUseKeyClick = async () => {
    //     try {
    //         const response = await authRequest(
    //             "POST",
    //             `/api/use-key`,
    //             {
    //                 key,
    //                 discord_username_hash: discordUsernameHash,
    //                 api_key: apiKey, // Include apiKey in the request body
    //             }
    //         );
    //         console.debug(response);
    //         setUseKeyResponse(response.message);
    //     } catch (error) {
    //         console.error("Error using key:", error);
    //         setUseKeyResponse("Failed to use key");
    //     }
    // };

    // const handleVerifyKeyClick = async () => {
    //     try {
    //         const response = await authRequest(
    //             "GET",
    //             `/api/verify-key?key=${key}&discord_username_hash=${discordUsernameHash}&api_key=${apiKey}` // Keep parameters in the URL for GET request
    //         );
    //         console.debug(response);
    //         setVerifyKeyResponse(
    //             `${response.status} | ${response.expiration_date}`
    //         );
    //     } catch (error) {
    //         console.error("Error verifying key:", error);
    //         setVerifyKeyResponse("Failed to verify key");
    //     }
    // };

    return (
        <Grid container spacing={3}>
            {/* Input Fields */}
            {/* <Grid item xs={12}>
                <TextField
                    label="Key"
                    variant="outlined"
                    fullWidth
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Discord Username Hash"
                    variant="outlined"
                    fullWidth
                    value={discordUsernameHash}
                    onChange={(e) => setDiscordUsernameHash(e.target.value)}
                />
            </Grid> */}
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNewKeyClick}
                >
                    Get New Key
                </Button>
                <Typography>{newKeyResponse}</Typography>
            </Grid>
            {/* <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUseKeyClick}
                >
                    Use Key
                </Button>
                <Typography>{useKeyResponse}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleVerifyKeyClick}
                >
                    Verify Key
                </Button>
                <Typography>{verifyKeyResponse}</Typography>
            </Grid> */}
        </Grid>
    );
}

export default KeyGen;
