import { createContext } from "react";

export const defaultAppState = {
    isLoading: true,
    isAuthenticated: false,
    setIsLoading: () => {},
    setIsAuthenticated: () => {},
    routeType: null,
    setRouteType: () => {},
};

export const AppStateContext = createContext(defaultAppState);