import React from "react";
import {
    DiscordMessage,
    DiscordOptionsContext,
    DiscordEmbed,
    DiscordEmbedFields,
    DiscordEmbedField,
} from "@danktuary/react-discord-message";
import discordOptions from "./Components/discordOptions";
import getEmbedProps from "./Components/EmbedProps";
import {
    generateStatsField,
    generateMovesField,
    generateDisplayField,
    generateLocationField,
    generateLocationInfoField,
    generateMapsField,
    generatePvPField,
    generateTimeField,
} from "./Components/FieldGenerators";
import Markdown from "marked-react";

const RaidPreview = ({ settings }) => {
    const { hide = [], image = "small" } = settings;
    const imageUrl = "https://img.pokemondb.net/sprites/go/shiny/mewtwo.png";
    const embedProps = getEmbedProps(
        hide,
        image,
        imageUrl,
        "#ff00ff",
        "Raid Level 0"
    );
    const titleStr =
        "✨💯 **Mewtwo** `dex:form:costume:gender`\n**IVs:** `15/15/15` | `100.0%`\n**Level:** `69.0` | **CP:** `9999`";
    const titlelines = titleStr.split("\n");
    embedProps.title = titlelines.map((line, index) => (
        <div key={index} className="markdown-line">
            <Markdown>{line}</Markdown>
        </div>
    ));
    let fields = [];

    if (!hide.includes("lineup")) {
        const lineupStr =
            "⚔️ **Charizard** | `20.7% HP`\n> **Level:** `32.0` | **CP:** `3113`\n🔋 **Ho Oh** | `100.0% HP`\n> **Level:** `20.0` | **CP:** `4367`\n🔋 **Ho Oh** | `100.0% HP`\n> **Level:** `20.0` | **CP:** `4350`\n🔋 **Reshiram** | `100.0% HP`\n> **Level:** `25.0` | **CP:** `3947`\n🔋 **Reshiram** | `100.0% HP`\n> **Level:** `25.0` | **CP:** `3205`\n🔋 **Reshiram** | `100.0% HP`\n> **Level:** `20.0` | **CP:** `3327`";
        const lineuplines = lineupStr.split("\n");

        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Lineup**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {lineuplines.map((line, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{line}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }

    if (!hide.includes("items")) {
        const itemsStr =
            "`3x` **Golden Razz Berry**\n`15x` **Hyper Potion**\n`1x` **Reroll Special Attack**\n`2x` **Golden Razz Berry**\n`3x` **Revive**";
        const itemslines = itemsStr.split("\n");

        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Items**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {itemslines.map((line, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{line}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }

    if (!hide.includes("top_stats")) {
        const topStatsStr =
            "`Us...04` | **Final Strike**\n`Us...05` | **Customize Avatar**\n`Us...03` | **Remote Distance:** `15493.6km`\n`Us...02` | **Walking Distance:** `9.8km`\n`Us...01` | **Pokemon Height:** `5.2m`";
        const topStatslines = topStatsStr.split("\n");

        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Top Stats**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {topStatslines.map((line, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{line}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }

    if (!hide.includes("damage_tier")) {
        const damageTierStr =
            "> **Duration:** `36.3s` | **HP:** `15169`\n` NAME    RANK DMG   DPS   DMG%   `\n` Us...01  1   1946  53.6  12.8%  `\n` Us...02  2   1934  53.3  12.7%  `\n`Us...03  3   1822  50.2  12.0%  `\n` Us...04  4   1521  41.9  10.0%  `\n` Us...05  5   1398  38.5  9.2%   `\n` Us...06  6   1326  36.5  8.7%   `\n` Us...07  7   1084  29.9  7.1%   `\n` Us...08  8   797   22.0  5.3%   `\n` Us...09  9   769   21.2  5.1%   `\n` Us...10  10  564   15.5  3.7%   `\n` Us...11  11  562   15.5  3.7%   `\n` Us...12  12  535   14.7  3.5%   `\n` Us...13  13  506   13.9  3.3%   `\n` Us...14  14  227   6.3   1.5%   `\n` Us...15  15  158   4.4   1.0%   `\n` Us...16  16  20    0.6   0.1%   `";
        const damageTierlines = damageTierStr.split("\n");

        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Damage Tier List `[16/20]`**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {damageTierlines.map((line, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{line}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }

    if (!hide.includes("time")) {
        generateTimeField(fields, hide);
    }

    if (!hide.includes("location")) {
        generateLocationField(fields, hide);
    }

    if (!hide.includes("location_info")) {
        generateLocationInfoField(fields, hide);
    }

    if (!hide.includes("maps")) {
        generateMapsField(fields, hide);
    }

    return (
        <>
            {(image === "large" || image === "both") && (
                <DiscordEmbed image={imageUrl} color={embedProps.color} />
            )}
            <DiscordEmbed {...embedProps}>
                <DiscordEmbedFields slot="fields">
                    {fields.map((field, index) => (
                        <DiscordEmbedField
                            key={index}
                            fieldTitle={field.name}
                            inline={field.inline}
                        >
                            {field.value}
                        </DiscordEmbedField>
                    ))}
                </DiscordEmbedFields>
            </DiscordEmbed>
        </>
    );
};

export default RaidPreview;
