import React, { createContext, useContext } from "react";
import axios from "axios";
import { AppStateContext } from "../AppStateContext";

const AuthRequestContext = createContext();

export const useAuthRequest = () => {
    return useContext(AuthRequestContext);
};

export const AuthRequestProvider = ({ children }) => {
    const {
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        routeType,
        setRouteType,
    } = useContext(AppStateContext);

    const authRequest = async (method, url, data = null) => {
        // Check if route type is public or undefined, then bypass authentication logic
        if (routeType === "public" || routeType === null) {
            console.log("Skipping authRequest for public/undefined route type");
            return;
        }

        const executeRequest = async (accessToken) => {
            const headers = { Authorization: `Bearer ${accessToken}` };
            return await axios({ method, url, headers, data });
        };

        const refreshToken = async () => {
            try {
                const res = await axios.get(
                    `${window.location.origin}/api/refresh/`,
                    { withCredentials: true }
                );
                const newAccessToken = res.data.access_token;
                sessionStorage.setItem("accessToken", newAccessToken);
                return newAccessToken;
            } catch (error) {
                sessionStorage.removeItem("accessToken");

                window.location.href = "/social-auth/login/discord/";
                throw error;
            }
        };

        let accessToken = sessionStorage.getItem("accessToken");
        if (!accessToken) {
            accessToken = await refreshToken();
        }

        try {
            const response = await executeRequest(accessToken);
            setIsAuthenticated(true);
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                try {
                    const newAccessToken = await refreshToken();
                    const response = await executeRequest(newAccessToken);
                    setIsAuthenticated(true); // Set isAuthenticated to true if the retried request succeeds
                    return response.data;
                } catch (refreshError) {
                    // If refreshing the token also fails, redirect to login
                    console.error(
                        "Failed to refresh token, redirecting to login:",
                        refreshError
                    );
                    window.location.href = "/social-auth/login/discord/";
                }
            } else {
                // Log other errors to the console
                console.error("Error executing request:", error);
                throw error;
            }
        }
    };

    return (
        <AuthRequestContext.Provider value={authRequest}>
            {children}
        </AuthRequestContext.Provider>
    );
};
