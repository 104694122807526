import React from "react";
import {
    DiscordMessage,
    DiscordOptionsContext,
    DiscordEmbed,
    DiscordEmbedFields,
    DiscordEmbedField,
} from "@danktuary/react-discord-message";
import discordOptions from "./Components/discordOptions";
import getEmbedProps from "./Components/EmbedProps";
import {
    generateStatsField,
    generateMovesField,
    generateDisplayField,
    generateLocationField,
    generateLocationInfoField,
    generateMapsField,
    generatePvPField,
    generateTimeField,
} from "./Components/FieldGenerators";
import Markdown from "marked-react";

const TradePreview = ({ settings }) => {
    const { hide = [], image = "small" } = settings;
    const imageUrl1 =
        "https://img.pokemondb.net/sprites/go/shiny/charmander.png";
    const imageUrl2 = "https://img.pokemondb.net/sprites/go/shiny/squirtle.png";
    const embedProps = getEmbedProps(
        hide,
        image,
        imageUrl2,
        "#FB6107",
        "Traded Us...01"
    );
    let fields = [];

    if (!hide.includes("player")) {
        const playerStr =
            "> **Level:** `20.0` | **CP:** `601`\n> **IVs:** `5`/`12`/`12` | `64.4%`\n> ⬆️\n> **Level:** `20.0` | **CP:** `640`\n> **IVs:** `13`/`15`/`10` | `84.4%`";
        const playerlines = playerStr.split("\n");

        fields.push({
            name: "",
            value: (
                <>
                    {playerlines.map((line, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{line}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }

    if (!hide.includes("friend")) {
        const friendStr =
            "> **Level:** `20.0` | **CP:** `601`\n> **IVs:** `5`/`12`/`12` | `64.4%`\n> ⬆️\n> **Level:** `20.0` | **CP:** `640`\n> **IVs:** `13`/`15`/`10` | `84.4%`";
        const friendlines = friendStr.split("\n");

        fields.push({
            name: "",
            value: (
                <>
                    {friendlines.map((line, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{line}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }

    if (!hide.includes("time")) {
        generateTimeField(fields, hide);
    }

    if (!hide.includes("location")) {
        generateLocationField(fields, hide);
    }

    if (!hide.includes("location_info")) {
        generateLocationInfoField(fields, hide);
    }

    if (!hide.includes("maps")) {
        generateMapsField(fields, hide);
    }

    return (
        <>
            <DiscordEmbed image={imageUrl1} color={embedProps.color} />
            <DiscordEmbed {...embedProps}>
                <DiscordEmbedFields slot="fields">
                    {fields.map((field, index) => (
                        <DiscordEmbedField
                            key={index}
                            fieldTitle={field.name}
                            inline={field.inline}
                        >
                            {field.value}
                        </DiscordEmbedField>
                    ))}
                </DiscordEmbedFields>
            </DiscordEmbed>
        </>
    );
};

export default TradePreview;
