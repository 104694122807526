import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import RaidStats from "../RaidStats";
import PolySettingsManager from "../PolySettingsManager";
import WebhookManager from "../CustomWebhook";
import { AppStateContext } from "../AppStateContext";

export const PublicRoutes = () => {
    const {
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        routeType,
    } = useContext(AppStateContext);

    useEffect(() => {
        if (!isAuthenticated) {
            setIsAuthenticated(true);
        }
    }, [isAuthenticated, setIsAuthenticated]);

    return (
        <Routes>
            <Route
                path="/raid"
                element={
                    <RaidStats />
                }
            />
            <Route
                path="/poly-settings"
                element={
                    <PolySettingsManager />
                }
            />
            <Route
                path="/custom-webhook"
                element={
                    <WebhookManager />
                }
            />
        </Routes>
    );
};

export default PublicRoutes;