import React, { useEffect, useContext } from "react";
import { AppStateContext } from "../AppStateContext";
import { useAuthRequest } from "./AuthRequestContext"; // Import the useAuthRequest hook

const Login = ({ children }) => {
    const {
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        routeType,
        setRouteType,
    } = useContext(AppStateContext);

    const authRequest = useAuthRequest(); // Use the authRequest function from context

    useEffect(() => {
        const validateToken = async () => {
            try {
                await authRequest("GET", `${window.location.origin}/api/home/`);
            } catch (error) {}
        };
        setRouteType("private");
        validateToken();
    }, []);

    if (!isLoading && !isAuthenticated) {
        window.location.href = "/social-auth/login/discord/";
        return null;
    }

    return <>{children}</>;
};

export default Login;
