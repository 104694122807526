// ChannelConfiguration.jsx

import React, { useState } from "react";
// import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EndpointComponent from "./EndpointComponent";
import { v4 as uuidv4 } from "uuid";

function ChannelConfiguration({
    channels,
    setChannels,
    openSnackbar,
    handleCloseSnackbar,
    snackbarSeverity,
    snackbarMessage,
}) {
    const [expanded, setExpanded] = useState(false);

    function addChannel() {
        let channelCounter = channels.length;
        setChannels([
            ...channels,
            {
                id: uuidv4(),
                name: `Channel ${channelCounter + 1}`,
                url: "",
                endpoints: [],
            },
        ]);
    }

    function removeChannel(index) {
        const newChannels = [...channels];
        newChannels.splice(index, 1);
        setChannels(newChannels);
    }

    function getUniqueCloneName(baseName) {
        let counter = 1;
        let newName = `${baseName} Clone (${counter})`;
        while (channels.some((channel) => channel.name === newName)) {
            newName = `${baseName} Clone (${counter + 1})`;
            counter++;
        }
        return newName;
    }

    function cloneChannel(index) {
        const originalChannel = channels[index];
        const clonedChannel = {
            ...originalChannel,
            id: uuidv4(), // Assign a new unique ID
            name: getUniqueCloneName(originalChannel.name), // Use the function to get a unique name
        };

        const newChannels = [...channels];
        newChannels.splice(index + 1, 0, clonedChannel); // Insert cloned channel right after the original
        setChannels(newChannels);
    }

    function handleChannelChange(index, key, value) {
        const newChannels = [...channels];
        newChannels[index][key] = value;
        setChannels(newChannels);
    }

    function updateEndpoints(index, updatedEndpoints) {
        const newChannels = [...channels];
        newChannels[index].endpoints = updatedEndpoints;
        setChannels(newChannels);
    }

    return (
        <div>
            {channels.map((channel, index) => (
                <Paper
                    key={channel.id} // Use the UUID as the key here
                    elevation={1}
                    sx={{
                        padding: "16px 8px 8px 8px",
                        margin: "0px 8px 8px 8px",
                    }}
                >
                    <div sx={{ marginBottom: "20px" }}>
                        <Grid
                            container
                            spacing={2}
                            display="flex"
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                    key={"channel-name-" + channel.id}
                                    value={channel.name}
                                    onChange={(e) =>
                                        handleChannelChange(
                                            index,
                                            "name",
                                            e.target.value
                                        )
                                    }
                                    label="Channel Name"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    value={channel.url}
                                    onChange={(e) =>
                                        handleChannelChange(
                                            index,
                                            "url",
                                            e.target.value
                                        )
                                    }
                                    label="Webhook URL"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6} lg={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => cloneChannel(index)}
                                    fullWidth
                                >
                                    Clone
                                </Button>
                            </Grid>

                            <Grid item xs={6} lg={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => removeChannel(index)}
                                    fullWidth
                                >
                                    Remove
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Accordion
                                    elevation={2}
                                    expanded={expanded === index}
                                    onChange={() =>
                                        setExpanded(
                                            expanded !== index ? index : false
                                        )
                                    }
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography>
                                            Webhook Endpoints
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            padding: "8px 8px 8px 8px"
                                        }}
                                    >
                                        <EndpointComponent
                                            endpoints={channel.endpoints}
                                            onUpdate={(updatedEndpoints) =>
                                                updateEndpoints(
                                                    index,
                                                    updatedEndpoints
                                                )
                                            }
                                            webhookURL={channel.url} // <-- Pass the webhook URL here
                                            name={channel.name} // <-- Pass the name of the channel here
                                            openSnackbar={openSnackbar}
                                            handleCloseSnackbar={
                                                handleCloseSnackbar
                                            }
                                            snackbarSeverity={snackbarSeverity}
                                            snackbarMessage={snackbarMessage}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            ))}
            <Paper
                key={0} // Use the UUID as the key here
                elevation={3}
                sx={{ padding: "8px", margin: "0px 8px 8px 8px" }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addChannel}
                    fullWidth
                >
                    Add Channel
                </Button>
            </Paper>
        </div>
    );
}

export default ChannelConfiguration;
