import React, { useState } from "react";
import { AppStateContext, defaultAppState } from "./AppStateContext";
import LoadingComponent from "./Theme/LoadingComponent";

const AppStateComponent = ({ children }) => {
    const [isLoading, setIsLoading] = useState(defaultAppState.isLoading);
    const [isAuthenticated, setIsAuthenticated] = useState(defaultAppState.isAuthenticated);
    const [routeType, setRouteType] = useState(defaultAppState.routeType);

    const contextValue = {
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        routeType,
        setRouteType,
    };

    console.log("AppStateComponent");

    return (
        <AppStateContext.Provider value={contextValue}>
            <LoadingComponent
                className={
                    !isAuthenticated || isLoading
                        ? "loading-overlay"
                        : "loading-overlay loading-finished"
                }
            />
            {children}
        </AppStateContext.Provider>
    );
};

export default AppStateComponent;
