import React from "react";
import { Routes, Route } from "react-router-dom";
import PolyAnalHome from "../PolyAnalHome";
import KeyGen from "../KeyGen/KeyGen";

export const PrivateRoutes = () => {
    return (
        <Routes>
            <Route path="/key-gen" element={<KeyGen />} />
            <Route path="/" element={<PolyAnalHome />} />
        </Routes>
    );
};

export default PrivateRoutes;
