import React from "react";
import { useTheme } from "@material-ui/core/styles";
import "./LoadingComponent.scss";

function LoadingComponent({ className }) {
    const theme = useTheme();

    // console.log("LoadingComponent | className: ", className)

    return (
        <div className={`loading-container${className ? " " + className : ""}`}>
            <div className="cat-container">
                <div className="cat">
                    <div className="ear ear--left"></div>
                    <div className="ear ear--right"></div>
                    <div className="face">
                        <div className="eye eye--left">
                            <div className="eye-pupil"></div>
                        </div>
                        <div className="eye eye--right">
                            <div className="eye-pupil"></div>
                        </div>
                        <div className="muzzle"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingComponent;
