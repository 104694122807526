import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField"; // <-- Importing TextField
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
    guide: {
        "& h1, & h2": {
            color: theme.palette.primary.main,
            alignSelf: "center",
        },
        "& h3": {
            color: theme.palette.primary.main,
            alignSelf: "start",
            padding: theme.spacing(1),
        },
        "& table": {
            width: "100%",
            borderCollapse: "separate",
            borderSpacing: "0",
        },
        "& th": {
            color: theme.palette.primary.main,
            borderBottom: `1px solid ${theme.palette.text.disabled}`,
            borderRight: `1px solid ${theme.palette.text.disabled}`,
            "&:last-child": {
                borderRight: "none",
            },
        },
        "& td": {
            borderTop: `1px solid ${theme.palette.text.disabled}`,
            borderRight: `1px solid ${theme.palette.text.disabled}`,
            "&:last-child": {
                borderRight: "none",
            },
            padding: theme.spacing(1),
        },
        "& td:first-child": {
            color: theme.palette.primary.main,
            // textAlign: "center",
            fontWeight: "bold",
            fontStyle: "italic",
        },
    },
    alwaysFocused: {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
            },
            "& fieldset": {
                borderColor: theme.palette.primary.main,
            },
        },
    },
}));

function FilterGuide() {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <Paper
            elevation={4}
            sx={{
                flex: "9",
                overflowY: "auto",
                p: 2,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                // alignItems: "center", // Hor centering the content
            }}
        >
            <Paper
                className={classes.guide}
                elevation={8}
                sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Hor centering the content
                    margin: theme.spacing(1),
                }}
            >
                <h1>Discord Webhook Filter Guide</h1>
            </Paper>
            <Paper
                className={classes.guide}
                elevation={8}
                sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Hor centering the content
                    margin: theme.spacing(1),
                }}
            >
                <h2>Filter Overview</h2>
                <table
                    style={{
                        width: "100%",
                        marginBottom: theme.spacing(2),
                        // borderCollapse: "collapse",
                    }}
                >
                    <thead>
                        <tr>
                            <th>Filter</th>
                            <th>Values</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Table rows from the README */}
                        <tr>
                            <td>shiny</td>
                            <td>True, False</td>
                            <td>Shiny Pokémon</td>
                        </tr>
                        <tr>
                            <td>hundo</td>
                            <td>True, False</td>
                            <td>100% IV Pokémon</td>
                        </tr>
                        <tr>
                            <td>shundo</td>
                            <td>True, False</td>
                            <td>Shiny 100% IV Pokémon</td>
                        </tr>
                        <tr>
                            <td>shadow</td>
                            <td>True, False</td>
                            <td>Shadow Pokémon</td>
                        </tr>
                        <tr>
                            <td>eType</td>
                            <td>
                                eType=='Incense', eType=='Wild', eType=='Raid',
                                eType=='Rocket'
                            </td>
                            <td>Encounter type</td>
                        </tr>
                        <tr>
                            <td>iv</td>
                            <td>int, 0 - 45</td>
                            <td>Sum of IVs</td>
                        </tr>
                        <tr>
                            <td>ivA</td>
                            <td>int, 0 - 15</td>
                            <td>Attack IV</td>
                        </tr>
                        <tr>
                            <td>ivD</td>
                            <td>int, 0 - 15</td>
                            <td>Defense IV</td>
                        </tr>
                        <tr>
                            <td>ivS</td>
                            <td>int, 0 - 15</td>
                            <td>Stamina IV</td>
                        </tr>
                        <tr>
                            <td>ivP</td>
                            <td>int / float, 0 - 100 / 0.0 - 100.0</td>
                            <td>IV percentage</td>
                        </tr>
                        <tr>
                            <td>geofence</td>
                            <td>[lat, lon, distance, optional: Unit]</td>
                            <td>Geofence (lat, lon, distance, unit)</td>
                        </tr>
                        <tr>
                            <td>id</td>
                            <td>[int], [int, int, ...]</td>
                            <td>Pokédex ID</td>
                        </tr>
                    </tbody>
                </table>
            </Paper>

            <Paper
                elevation={12}
                sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center", // Hor centering the content
                    margin: theme.spacing(1),
                    flexGrow: 1,
                }}
            >
                <h3>Boolean Overview</h3>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Smaller"
                            value="<"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Greater"
                            value=">"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Smaller Eq"
                            value="<="
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Greater Eq"
                            value=">="
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Equal"
                            value="=="
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Not Equal"
                            value="!="
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Negate"
                            value="!"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Negate"
                            value="not"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="And"
                            value="&"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="And"
                            value="and"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Or"
                            value="|"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Or"
                            value="or"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="True"
                            value="true"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="True"
                            value="True"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="False"
                            value="false"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="False"
                            value="False"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Inside"
                            value=">>"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1}>
                        <TextField
                            fullWidth
                            label="Inside"
                            value="in"
                            variant="outlined"
                            InputProps={{
                                readOnly: true, // Make it read-only instead of disabled
                            }}
                            InputLabelProps={{
                                shrink: true, // Always keep the label in the "shrunken" state
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper
                className={classes.guide}
                elevation={8}
                sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center", // Hor centering the content
                    margin: theme.spacing(1),
                }}
            >
                <h2>Example Filters</h2>
                {/* Example Filters content */}

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>Send all shinys.</h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Typography>Short</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="shiny"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Long</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="shiny==True"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>Send all shinys or hundos.</h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Typography>Short</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="shiny|hundo"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Long</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="shiny or hundo"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>Send all shundos.</h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Typography>Short</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="shundo"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Long</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="shundo==True"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Long</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="shiny and hundo"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Long</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="shiny&hundo"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>Send only specific Pokédex IDs.</h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Typography>Short</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="id&gt;&gt;[1,2,3]"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Long</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="id in [1, 2, 3]"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>Sum of all IVs, so 45 for a hundo</h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="iv&gt;=30"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>
                        Using the individual IVs, attack, defense, and stamina
                    </h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="ivA&gt;=10&amp;ivD&gt;=10&amp;ivS&gt;=10"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>Only send if above 90% IV</h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="ivP&gt;=90"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>Send only specific Pokédex IDs.</h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Typography>Short</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="hundo&amp;!(eType=='Incense'|eType=='Raid'|eType=='Rocket')"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Long</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="id in [1, 2, 3]"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>
                        All Hundos and not Incense or not Raid or not Rocket.
                    </h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={2}>
                            <Typography>Short</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="hundo&amp;eType=='Wild'"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Short</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="hundo&amp;(eType!='Incense'|eType!='Raid'|eType!='Rocket')"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Short</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="hundo&amp;eType=='Wild'"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Long</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="hundo and not (eType=='Incense' or
                                        eType=='Raid' or eType=='Rocket')"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Paper
                    elevation={12}
                    sx={{
                        p: 1,
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center", // Hor centering the content
                        margin: theme.spacing(1),
                        flexGrow: 1,
                    }}
                >
                    <h3>
                        Filter using a Geofence with center lat, lon, and
                        distance.
                    </h3>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            <Typography>Kilometers</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="geofence([9.9, 19.9, 10])"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Kilometers</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="geofence([9.9, 19.9, 10, 'K'])"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Nautical Miles</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="geofence([9.9, 19.9, 10, 'N'])"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Miles</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value="geofence([9.9, 19.9, 10, 'M'])"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true, // Make it read-only instead of disabled
                                }}
                                InputLabelProps={{
                                    shrink: true, // Always keep the label in the "shrunken" state
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Paper>
        </Paper>
    );
}

export default FilterGuide;
