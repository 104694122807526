import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import { PublicRoutes } from "./PublicRoutes";
import { PrivateRoutes } from "./PrivateRoutes";
import { AppStateContext } from "../AppStateContext";
import Login from "../DiscordLogin/Login";
import { AuthRequestProvider } from "../DiscordLogin/AuthRequestContext";

const AppRoutes = () => {
    const {
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        routeType,
    } = useContext(AppStateContext);
    
    console.log("Rendering AppRoutes");

    return (
        <Router>
            <PublicRoutes />
            <AuthRequestProvider>
                <Login>
                    <PrivateRoutes />
                </Login>
            </AuthRequestProvider>
        </Router>
    );
};

export default AppRoutes;
