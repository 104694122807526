import React, { useState, useEffect } from "react";
import {
    AppBar,
    Button,
    TextField,
    Typography,
    Toolbar,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    InputLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    inputRoot: {
        "&$inputFocused $notchedOutline": {
            borderColor: "white",
        },
    },
    inputFocused: {},
    notchedOutline: {},
    labelRoot: {
        "&$labelFocused": {
            color: "white",
        },
    },
    labelFocused: {},
    appBar: {
        padding: "4px",
        margin: "0px",
    },
    toolBar: {
        padding: "4px",
    },
});

function PolySettingsManager({
    theme,
    mode,
    handle,
    setIsLoading,
    setIsAuthenticated,
}) {
    const [fields, setFields] = useState({});
    const [fileName, setFileName] = useState("");
    const classes = useStyles();

    const settingsGroups = {
        // Group settings together as needed
        "Base Settings": [
            { name: "startup_delay", type: "text" },
            { name: "start_on_boot", type: "switch" },
            { name: "proximity_sensor", type: "switch" },
            { name: "lightweight_mode", type: "switch" },
            { name: "max_fps", type: "number" },
        ],
        "Geofence Settings": [
            { name: "geofence_center", type: "text" },
            { name: "geofence_radius", type: "text" },
        ],
    };

    useEffect(() => {
        setIsLoading(false);
        setIsAuthenticated(true);
    }, [setIsLoading]);

    const handleFileChange = (event) => {
        const jsonFile = event.target.files[0];

        setIsLoading(true);

        const reader = new FileReader();

        reader.onload = async (event) => {
            try {
                const jsonObj = JSON.parse(event.target.result);
                console.log(jsonObj);

                setFields(jsonObj);
                setFileName(jsonFile.name);
            } catch (err) {
                console.error("Error parsing JSON:", err);
                setFileName("Invalid JSON file");
            } finally {
                setIsLoading(false);
            }
        };

        reader.onerror = (event) => {
            console.error("Error reading file:", event);
            setIsLoading(false);
            setFileName("Error reading file");
        };

        reader.readAsText(jsonFile);
    };

    const handleFieldChange = (fieldName) => (event) => {
        const value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;

        setFields((prevFields) => ({
            ...prevFields,
            [fieldName]: value,
        }));
    };

    const renderField = (field) => {
        if (field.type === "switch") {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                    }}
                >
                    <Switch
                        checked={fields[field.name] || false}
                        onChange={(event) =>
                            handleFieldChange(field.name)(event.target.checked)
                        }
                    />
                    <Typography
                        variant="subtitle2"
                        style={{
                            marginLeft: "10px",
                            fontWeight: "normal",
                        }}
                    >
                        {field.name}
                    </Typography>
                </div>
            );
        } else if (field.type === "number") {
            return (
                <TextField
                    label={field.name}
                    value={fields[field.name] || ""}
                    onChange={handleFieldChange(field.name)}
                    variant="outlined"
                    type="number"
                    style={{ marginTop: "10px" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        } else {
            return (
                <TextField
                    label={field.name}
                    value={fields[field.name] || ""}
                    onChange={handleFieldChange(field.name)}
                    variant="outlined"
                    style={{ marginTop: "10px" }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
        }
    };

    return (
        <div>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <TextField
                        label="File Name"
                        value={fileName}
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                            classes: {
                                root: classes.inputRoot,
                                focused: classes.inputFocused,
                                notchedOutline: classes.notchedOutline,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                root: classes.labelRoot,
                                focused: classes.labelFocused,
                            },
                        }}
                        style={{ marginRight: "10px" }}
                    />
                    <input
                        accept="application/JSON"
                        id="json-upload"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                    />
                    <label htmlFor="json-upload">
                        <Button variant="contained" component="span">
                            Upload JSON
                        </Button>
                    </label>
                </Toolbar>
            </AppBar>
            <Box display="flex" flexDirection="column" p={1}>
                {Object.keys(settingsGroups).map((groupName, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{groupName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display="flex" flexDirection="column" p={1}>
                                {settingsGroups[groupName].map(
                                    (field, index) => (
                                        <div key={index}>
                                            {renderField(field)}
                                        </div>
                                    )
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </div>
    );
}

export default PolySettingsManager;
