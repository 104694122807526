import React from "react";
import ThemeProviderComponent from "./Theme/ThemeProviderComponent";
import AppStateComponent from "./AppStateComponent";
import AppRoutes from "./Routes/AppRoutes";
import "./App.css";

function App() {
    console.log("Rendering App");

    return (
        <ThemeProviderComponent>
            <AppStateComponent>
                <AppRoutes />
            </AppStateComponent>
        </ThemeProviderComponent>
    );
}

export default App;