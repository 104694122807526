import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField"; // <-- Importing TextField
import { useTheme } from "@mui/material/styles";

import FilterGuide from "./FilterGuide";

function FilterGuideBackdrop({ open, handleClose, currentFilter, handleSave }) {
    const theme = useTheme();

    const [filterValue, setFilterValue] = useState(currentFilter); // Local state for the filter value

    useEffect(() => {
        setFilterValue(currentFilter); // Update the local state when the prop changes
    }, [currentFilter]);

    return (
        <Backdrop
            open={open}
            sx={{
                zIndex: 1300,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                overflow: "hidden",
            }}
        >
            <Paper
                elevation={4}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "calc(100% - min(8dvh, 8dvw))",
                    height: "calc(100% - min(8dvh, 8dvw))",
                    padding: theme.spacing(2),
                    gap: theme.spacing(2),
                }}
            >
                <FilterGuide />

                <Paper
                    elevation={4}
                    sx={{
                        flex: "1",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        p: 2,
                    }}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                label="Filter"
                                value={filterValue}
                                onChange={(e) => setFilterValue(e.target.value)} // Update the local state
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => handleSave(filterValue)} // Save the current filter value
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleClose}
                                fullWidth
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Paper>
        </Backdrop>
    );
}

export default FilterGuideBackdrop;
