// FieldGenerators.js
import React from "react";
import Markdown from "marked-react";

export const generateStatsField = (fields, hide) => {
    if (!hide.includes("stats")) {
        let stats = [];
        if (!hide.includes("level")) {
            stats.push("**Level:** `13.0`");
        }
        if (!hide.includes("cp")) {
            stats.push("**CP:** `364`");
        }
        if (!hide.includes("ivs")) {
            stats.push("**IVs:** `15/15/15` | `100.0%`");
        }
        if (!hide.includes("height")) {
            stats.push("**Height:** `58.3 cm`");
        }
        if (!hide.includes("weight")) {
            stats.push("**Weight:** `7.953 kg`");
        }
        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Stats**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {stats.map((stat, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{stat}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }
};

export const generateMovesField = (fields, hide) => {
    if (!hide.includes("moves")) {
        const movesStr =
        "**Fast:** `Ember`\n**Charge:** `Flamethrower`";
        const moveslines = movesStr.split("\n");
        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Moves**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {moveslines.map((item, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{item}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }
};

export const generateDisplayField = (fields, hide) => {
    if (!hide.includes("display")) {
        let display = [];
        if (!hide.includes("gender")) {
            display.push("**Gender:** ♂️");
        }
        if (!hide.includes("shiny")) {
            display.push("**Shiny:** ✅");
        }
        if (!hide.includes("shadow")) {
            display.push("**Shadow:** ✅");
        }
        if (!hide.includes("form")) {
            display.push("**Form:** `Normal`");
        }
        if (!hide.includes("costume")) {
            display.push("**Costume:** `Costume`");
        }
        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Display**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {display.map((item, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{item}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }
};

export const generatePvPField = (fields, hide) => {
    if (!hide.includes("pvp")) {
        let pvp = ["` L RANK LVL  CP   `", "> **Charmander**"];
        if (!hide.includes("gl")) {
            pvp.push("` G 1    50.0 1108 `");
        }
        if (!hide.includes("ul")) {
            pvp.push("` U 1    50.0 1108 `");
        }
        if (!hide.includes("ml")) {
            pvp.push("` M 1    50.0 1108 `");
        }
        pvp.push("> **Charmeleon**");
        if (!hide.includes("gl")) {
            pvp.push("` G 1527 33.5 1499 `");
        }
        if (!hide.includes("ul")) {
            pvp.push("` U 1    50.0 1868 `");
        }
        if (!hide.includes("ml")) {
            pvp.push("` M 1    50.0 1868 `");
        }
        pvp.push("> **Charizard**");
        if (!hide.includes("gl")) {
            pvp.push("` G 1053 18.0 1486 `");
        }
        if (!hide.includes("ul")) {
            pvp.push("` U 1170 30.5 2497 `");
        }
        if (!hide.includes("ml")) {
            pvp.push("` M 1    50.0 3266 `");
        }
        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**PvP**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {pvp.map((line, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{line}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }
};

export const generateTimeField = (fields, hide) => {
    if (!hide.includes("time")) {
        let time = [];
        if (!hide.includes("date")) {
            time.push("📅 `7. December 2023`");
        }
        if (!hide.includes("timestamp")) {
            time.push("🕰️ `03:25:18`");
        }
        if (!hide.includes("timer")) {
            time.push("⏲️ `2 Months ago`");
        }
        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Time**"}</Markdown>
                </div>
            ),
            value: (
                <>
                    {time.map((item, index) => (
                        <div key={index} className="markdown-line">
                            <Markdown>{item}</Markdown>
                        </div>
                    ))}
                </>
            ),
            inline: false,
        });
    }
};

export const generateLocationField = (fields, hide) => {
    if (!hide.includes("location")) {
        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Location**"}</Markdown>
                </div>
            ),
            value: (
                <div className="markdown-line">
                    <Markdown>{"`40.770485,-73.952591`"}</Markdown>
                </div>
            ),
            inline: false,
        });
    }
};

export const generateLocationInfoField = (fields, hide) => {
    if (!hide.includes("location_info")) {
        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Location Info**"}</Markdown>
                </div>
            ),
            value: (
                <div className="markdown-line">
                    <Markdown>
                        {
                            "```\n[US] United States, 10021, New York,\nNew York County, Manhattan,\nEast 77th Street, 422\n```"
                        }
                    </Markdown>
                </div>
            ),
            inline: false,
        });
    }
};

export const generateMapsField = (fields, hide) => {
    if (!hide.includes("maps")) {
        fields.push({
            name: (
                <div className="markdown-line">
                    <Markdown>{"**Maps**"}</Markdown>
                </div>
            ),
            value: (
                <div className="markdown-line">
                    <Markdown>
                        {
                            "[[**Google Maps**]](https://maps.google.com/maps?q=40.770485,-73.952591) [[**Apple Maps**]](http://maps.apple.com/?daddr=40.770485,-73.952591)"
                        }
                    </Markdown>
                </div>
            ),
            inline: false,
        });
    }
};
