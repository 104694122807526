import React, { useState, useRef, useEffect, useContext } from "react";
import ErrorBoundary from "./ErrorBoundary";
import "./RaidStats.css";
import RaidSelect from "./RaidStatsComponents/RaidSelect";
import RaidChart from "./RaidStatsComponents/RaidChart";
import useFetchRaidData from "./RaidStatsComponents/useFetchRaidData";
import ModeToggle from "./Theme/ModeToggle.js";
import { AppStateContext } from "./AppStateContext";
import { ThemeContext } from "./Theme/ThemeContext";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

function RaidStats() {
    const theme = useTheme();
    const { mode, toggleMode } = useContext(ThemeContext);
    const {
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        routeType,
        setRouteType,
    } = useContext(AppStateContext);

    // Use useEffect to set the route type when the component mounts
    useEffect(() => {
        setRouteType("public");
    }, [setRouteType]);

    // setIsAuthenticated(true);
    const [selected, setSelected] = useState(0);
    const [selectedStr, setSelectedStr] = useState("");

    // Use the custom hook to fetch data
    const { data, dataOld, loading, setData, setDataOld, setLoading } =
        useFetchRaidData(selected);

    // Sync the value of isLoading prop with the value of loading state
    useEffect(() => {
        setIsLoading(loading);
    }, [loading, setIsLoading]); // re-run whenever 'loading' changes

    const handleRaidChange = (event) => {
        const selectedDataObj = data.find(
            (item) => item.menuItem === event.target.value
        );
        const selectedIndex = data.indexOf(selectedDataObj);
        setSelectedStr(event.target.value);
        setSelected(selectedIndex);
    };

    if (loading) {
        return null;
    }

    return (
        <ErrorBoundary>
            <Paper
                elevation={2}
                style={{
                    padding: "30px 20px 20px 20px",
                    margin: "10px",
                }}
                className="top-container"
            >
                <div className="select-container">
                    <RaidSelect
                        data={data}
                        selectedStr={selectedStr}
                        onChange={handleRaidChange}
                    />
                </div>
                <div className="dark-mode-container">
                    <ModeToggle mode={mode} handle={toggleMode} />
                </div>
            </Paper>
            <Paper
                elevation={1}
                style={{
                    padding: "10px 10px 10px 10px",
                    margin: "0px 10px 10px 10px",
                }}
                className="chart-container"
            >
                <RaidChart
                    selected={selected}
                    data={data}
                    dataOld={dataOld}
                    setSelectedStr={setSelectedStr}
                    theme={theme}
                />
            </Paper>
        </ErrorBoundary>
    );
}

export default RaidStats;
