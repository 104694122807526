import { useState, useEffect } from "react";
import axios from "axios";

const useFetchRaidData = (selectedRaid) => {
    const [data, setData] = useState([]);
    const [dataOld, setDataOld] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // var fCount = 0; // DEBUG
        const fetchData = async () => {
            // fCount++; // DEBUG
            try {
                // const response = await axios.get(
                //     "http://127.0.0.1:8000/raid/stats/"
                // );
                const response = await axios.get("/raid/stats/");

                const orderData = (selectedData) => {
                    const orderedData = {};
                    const ivKeys = Object.keys(selectedData).filter((key) =>
                        key.startsWith("iv_")
                    );
                    const otherKeys = Object.keys(selectedData).filter(
                        (key) => !key.startsWith("iv_")
                    );

                    if (ivKeys.length > 0) {
                        ivKeys
                            .map((key) => key.slice(3).replace("_", "."))
                            .sort((a, b) => parseFloat(a) - parseFloat(b))
                            .forEach((key) => {
                                orderedData[`${key}%`] =
                                    selectedData[`iv_${key.replace(".", "_")}`];
                            });
                    }

                    if (otherKeys.length === 3) {
                        orderedData.hundos = selectedData.hundos;
                        orderedData.shinys = selectedData.shinys;
                        orderedData.shundos = selectedData.shundos;
                        // orderedData.shundos = selectedData.shundos + fCount; // DEBUG
                    } else {
                        otherKeys.forEach((key) => {
                            orderedData[key] = selectedData[key];
                        });
                    }

                    return orderedData;
                };

                const processedData = Object.entries(response.data).map(
                    ([key, value]) => {
                        const count = value.count;
                        // const count = value.count + fCount; // DEBUG
                        delete value.count;

                        const filteredValue = Object.fromEntries(
                            Object.entries(value).filter(
                                ([innerKey]) =>
                                    innerKey !== "iv_100_0" && innerKey !== "id"
                            )
                        );

                        const orderedValue = orderData(filteredValue);

                        const menuItem = `${count}x ${key.replace("_", " ")}`;
                        const labels = Object.keys(orderedValue);
                        const data = Object.values(orderedValue);

                        return {
                            menuItem,
                            count,
                            labels,
                            data,
                        };
                    }
                );
                
                if (dataOld.length === 0) {
                    setDataOld(processedData);
                } else {
                    setDataOld(data);
                }

                setData(processedData);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 10000);

        return () => clearInterval(intervalId);
    }, [selectedRaid]);

    return { data, dataOld, loading, setData, setDataOld, setLoading };
};

export default useFetchRaidData;
