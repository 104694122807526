import { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import createChartConfig from "./ChartConfig";

function useInitializeChart(chartRef, data, selected, loading, setSelectedStr, theme, selectedRef) {
    const [chartInstanceRef, setChartInstanceRef] = useState(null);
    var chartCreated = false;

    useEffect(() => {
        if (chartRef.current && data && data.length && !loading) {
            if (!chartCreated) {
                chartCreated = true;
                const ctx = chartRef.current.getContext("2d");
                const config = createChartConfig(data, selectedRef, theme);
                // console.dir(config)
                const chartInstance = new Chart(ctx, config);
                setChartInstanceRef(chartInstance);
                setSelectedStr(data[selected].menuItem)
            }
        }
    }, [loading]);

    return chartInstanceRef;
}

export default useInitializeChart;
