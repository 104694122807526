import React, { useState } from "react";
// import { Paper, Button, Select, MenuItem, FormControl, InputLabel, TextField, FormControlLabel, Checkbox, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import TextFieldWithIcon from "./TextFieldWithIcon";
import WebhookPreview from "./EmbedPreviews/webhookPreview";
import { v4 as uuidv4 } from "uuid";

import {
    DiscordDefaultOptions,
    DiscordMessage,
    DiscordMessages,
    DiscordOptionsContext,
    DiscordEmbed,
    DiscordEmbedField,
    DiscordEmbedFields,
} from "@danktuary/react-discord-message";
import { Container } from "@mui/material";

const discordOptions = {
    ...DiscordDefaultOptions,
    profiles: {
        bot: {
            author: "Webhook Message",
            avatar: "https://cdn.discordapp.com/icons/1100461343460561036/8ca0c1c34d74f4d34c75870e127b78dc.webp",
        },
    },
};

const hideOptions = {
    encounter: [
        "title",
        "author",
        "user",
        "censor",
        "source",
        "color",
        "image",
        "stats",
        "level",
        "cp",
        "ivs",
        "height",
        "weight",
        "moves",
        "display",
        "gender",
        "shiny",
        "shadow",
        "form",
        "pvp",
        "gl",
        "ul",
        "ml",
        "time",
        "date",
        "timestamp",
        "timer",
        "location",
        "location_info",
        "maps",
        "map_image",
    ],
    catch: [
        "title",
        "author",
        "user",
        "censor",
        "source",
        "color",
        "image",
        "stats",
        "level",
        "cp",
        "ivs",
        "height",
        "weight",
        "moves",
        "display",
        "gender",
        "shiny",
        "shadow",
        "form",
        "rewards",
        "pvp",
        "gl",
        "ul",
        "ml",
        "time",
        "date",
        "timestamp",
        "timer",
        "location",
        "location_info",
        "maps",
        "map_image",
    ],
    flee: [
        "title",
        "author",
        "user",
        "censor",
        "source",
        "color",
        "image",
        "stats",
        "level",
        "cp",
        "ivs",
        "height",
        "weight",
        "moves",
        "display",
        "gender",
        "shiny",
        "shadow",
        "form",
        "pvp",
        "gl",
        "ul",
        "ml",
        "time",
        "date",
        "timestamp",
        "timer",
        "location",
        "location_info",
        "maps",
        "map_image",
    ],
    hatch: [
        "title",
        "author",
        "user",
        "censor",
        "source",
        "color",
        "image",
        "stats",
        "level",
        "cp",
        "ivs",
        "height",
        "weight",
        "moves",
        "display",
        "gender",
        "shiny",
        "shadow",
        "form",
        "rewards",
        "pvp",
        "gl",
        "ul",
        "ml",
        "time",
        "date",
        "timestamp",
        "timer",
        "location",
        "location_info",
        "maps",
        "map_image",
    ],
    camp: [
        "title",
        "author",
        "user",
        "censor",
        "source",
        "color",
        "image",
        "stats",
        "level",
        "cp",
        "ivs",
        "height",
        "weight",
        "moves",
        "display",
        "gender",
        "shiny",
        "shadow",
        "form",
        "camp",
        "pvp",
        "gl",
        "ul",
        "ml",
        "time",
        "date",
        "timestamp",
        "timer",
        "location",
        "location_info",
        "maps",
        "map_image",
    ],
    cooldown: [
        "title",
        "author",
        "user",
        "censor",
        "color",
        "time",
        "date",
        "timestamp",
        "timer",
        "location",
        "location_info",
        "maps",
        "map_image",
    ],
    trade: [
        "title",
        "author",
        "user",
        "censor",
        "source",
        "color",
        "image",
        "time",
        "date",
        "timestamp",
        "timer",
        "location",
        "location_info",
        "maps",
        "map_image",
        "player",
        "friend",
    ],
    raid: [
        "title",
        "author",
        "user",
        "censor",
        "source",
        "color",
        "image",
        "time",
        "date",
        "timestamp",
        "timer",
        "location",
        "location_info",
        "maps",
        "map_image",
        "lineup",
        "items",
        "top_stats",
        "damage_tier",
    ],
};

const marks = [
    {
        value: 0,
        label: "0",
    },
    {
        value: 10,
        label: "10",
    },
    {
        value: 60,
        label: "60",
    },
    {
        value: 120,
        label: "120",
    },
];

function valuetext(value) {
    return `${value} min`;
}

function EndpointComponent({
    endpoints: initialEndpoints,
    onUpdate,
    webhookURL,
    name,
    openSnackbar,
    handleCloseSnackbar,
    snackbarSeverity,
    snackbarMessage,
}) {
    const [endpoints, setEndpoints] = useState(initialEndpoints);
    const endpointOptions = Object.keys(hideOptions);

    function addEndpoint() {
        if (endpoints.length < endpointOptions.length) {
            const unusedOptions = endpointOptions.filter(
                (option) =>
                    !endpoints.some((endpoint) => endpoint.endpoint === option)
            );

            const newEndpoint = {
                id: uuidv4(),
                endpoint: unusedOptions[0], // First unused option
                filter: "",
                image: "small",
                minCooldown: 20,
                hide: [],
            };

            const newEndpoints = [...endpoints, newEndpoint];
            setEndpoints(newEndpoints);
            onUpdate(newEndpoints);
        }
    }

    function removeEndpoint(index) {
        const updatedEndpoints = [...endpoints];
        updatedEndpoints.splice(index, 1);
        setEndpoints(updatedEndpoints);
        onUpdate(updatedEndpoints);
    }

    function handleEndpointChange(index, key, value) {
        const updatedEndpoints = endpoints.map((ep, idx) =>
            idx === index ? { ...ep, [key]: value } : ep
        );
        setEndpoints(updatedEndpoints);
        onUpdate(updatedEndpoints);
    }

    return (
        <>
            {endpoints.map((endpoint, index) => (
                <Paper
                    key={index}
                    variant="outlined"
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "16px 8px 8px 8px",
                        marginBottom: "8px",
                    }}
                >
                    <Grid
                        container
                        spacing={1}
                        display="flex"
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            lg={2}
                        >
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Endpoint</InputLabel>
                                <Select
                                    value={endpoint.endpoint}
                                    onChange={(e) =>
                                        handleEndpointChange(
                                            index,
                                            "endpoint",
                                            e.target.value
                                        )
                                    }
                                    label="Endpoint"
                                >
                                    {endpointOptions
                                        .filter(
                                            (opt) =>
                                                !endpoints.some(
                                                    (ep) =>
                                                        ep.endpoint === opt &&
                                                        ep.id !== endpoint.id
                                                )
                                        )
                                        .map((option) => (
                                            <MenuItem
                                                key={option}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} lg={10}>
                            {/* <TextField
                                    value={endpoint.filter}
                                    onChange={(e) => handleEndpointChange(index, 'filter', e.target.value)}
                                    label="Filter Key"
                                    variant="outlined"
                                    fullWidth
                                /> */}
                            <TextFieldWithIcon
                                onChange={(e) =>
                                    handleEndpointChange(
                                        index,
                                        "filter",
                                        e.target.value
                                    )
                                }
                                endpoint={endpoint}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion
                                variant="outlined"
                                TransitionProps={{ unmountOnExit: true }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>Endpoint Settings</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        container
                                        spacing={2}
                                        display="flex"
                                        sx={{
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}
                                    >
                                        {endpoint.endpoint === "camp" && (
                                            <Grid item xs={12}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        style={{
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        Minimum Cooldown (in
                                                        minutes)
                                                    </Typography>
                                                </Grid>
                                                <Slider
                                                    defaultValue={
                                                        endpoint.minCooldown
                                                    }
                                                    step={1}
                                                    min={0}
                                                    max={120}
                                                    marks={marks}
                                                    valueLabelFormat={valuetext}
                                                    valueLabelDisplay="auto"
                                                    onChange={(e) =>
                                                        handleEndpointChange(
                                                            index,
                                                            "minCooldown",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography
                                                style={{
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                Hide Message Part
                                            </Typography>
                                        </Grid>
                                        {hideOptions[endpoint.endpoint].map(
                                            (option) => (
                                                <Grid
                                                    key={option}
                                                    item
                                                    xs={6}
                                                    lg={2}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    endpoint.hide?.includes(
                                                                        option
                                                                    ) || false
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const updatedHide =
                                                                        [
                                                                            ...(endpoint.hide ||
                                                                                []),
                                                                        ];
                                                                    if (
                                                                        e.target
                                                                            .checked
                                                                    ) {
                                                                        updatedHide.push(
                                                                            option
                                                                        );
                                                                    } else {
                                                                        const index =
                                                                            updatedHide.indexOf(
                                                                                option
                                                                            );
                                                                        if (
                                                                            index >
                                                                            -1
                                                                        )
                                                                            updatedHide.splice(
                                                                                index,
                                                                                1
                                                                            );
                                                                    }
                                                                    handleEndpointChange(
                                                                        index,
                                                                        "hide",
                                                                        updatedHide
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        label={option}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                        {[
                                            "encounter",
                                            "catch",
                                            "flee",
                                            "hatch",
                                            "camp",
                                            "raid",
                                        ].includes(endpoint.endpoint) && (
                                            <>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">
                                                            Image Size
                                                        </FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-label="image size"
                                                            name="image-size"
                                                            value={
                                                                endpoint.image
                                                            }
                                                            onChange={(e) =>
                                                                handleEndpointChange(
                                                                    index,
                                                                    "image",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            {[
                                                                "small",
                                                                "large",
                                                                "both",
                                                            ].map((size) => (
                                                                <FormControlLabel
                                                                    key={size}
                                                                    value={size}
                                                                    control={
                                                                        <Radio />
                                                                    }
                                                                    label={size}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12} key={endpoint.id}>
                            <Accordion
                                variant="outlined"
                                TransitionProps={{ unmountOnExit: true }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>Endpoint Preview</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Container
                                        container
                                        sx={{ overflowX: "auto" }}
                                    >
                                        <WebhookPreview settings={endpoint} />
                                    </Container>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => removeEndpoint(index)}
                                fullWidth
                            >
                                Remove Endpoint
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            ))}
            <Button
                variant="contained"
                color="primary"
                onClick={addEndpoint}
                disabled={endpoints.length >= endpointOptions.length}
                fullWidth
            >
                Add Endpoint
            </Button>
        </>
    );
}

export default EndpointComponent;
